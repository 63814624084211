import React, { useState, useEffect } from 'react';
import { ElementStore, ReactFormGenerator } from '@clientdiary/react-form-builder2';

import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';

export default function PreviewForm(props) {
  const [data, setData] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    ElementStore.subscribe(state => setData(state.data));
  }, [])

  return (
    <>
      <Button color="secondary" onClick={() => setPreviewVisible(true)}>Preview Form</Button>

      <Dialog
        open={previewVisible}
        disablePortal
      >
        <DialogContent sx={{ pl: 4, pr: 4 }}>
          <ReactFormGenerator
            toolbarItems={props.toolbarItems}
            download_path=""
            back_action="/"
            back_name="Back"
            answer_data={{}}
            action_name="Save"
            form_action="/"
            form_method="POST"
            hide_actions={true}
            data={data}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setPreviewVisible(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}