export const templateForms = [
  {
    id: '1',
    name: 'Blank Form',
    formData: []
  },
  {
    id: '2',
    name: 'New Client Consultation Form',
    formData: [
      {
        "id": "1E1B9390-93F6-4053-B2C5-736417F2662B",
        "element": "TwoColumnRow",
        "text": "Two Column Row",
        "required": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "two_col_row_E591C376-A823-49E3-8B45-C2E3D0322187",
        "childItems": [
          "9AE128BE-A482-4074-A6B1-9C40CA8FECB1",
          "78A20A5C-9129-4B2E-A173-3ACD771C28E9"
        ],
        "isContainer": true
      },
      {
        "id": "27159AD6-96EC-4027-ADC6-A6071D36C0E2",
        "element": "ThreeColumnRow",
        "text": "Three Columns Row",
        "required": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "three_col_row_F632B8A5-2D48-474E-9B15-440ABC5EAE53",
        "childItems": [
          "2C516DCF-3AC7-4C36-8405-A43BBA4F0F41",
          "D267A8B6-0B9C-4D7D-9922-929B36CA87CE",
          "CB0DE3C1-2158-4C87-8657-2116E25D2A8E"
        ],
        "isContainer": true
      },
      {
        "id": "F48F3E07-33B7-4C28-815B-4BE3C7FAD665",
        "element": "TextInput",
        "text": "Text Input",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "text_input_D0E8B19A-D3EF-4274-8653-5586FEF9B10D",
        "label": "Address ",
        "dirty": false
      },
      {
        "id": "CB7D2471-B014-4CF0-A234-C1D830E0B940",
        "element": "ThreeColumnRow",
        "text": "Three Columns Row",
        "required": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "three_col_row_29CBA28D-A388-4394-853A-2513A61A6E19",
        "childItems": [
          "A0309315-5B08-496C-849C-34EF4A11FEF3",
          "D5D251DB-5620-411B-8A59-7A1051B7AB48",
          "D515565B-72EB-4689-B278-82D4295BA160"
        ],
        "isContainer": true
      },
      {
        "id": "C4A1A433-C5BE-4C60-9EBB-954E9D75EC29",
        "element": "TextInput",
        "text": "Text Input",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "text_input_4D8C7E83-35F5-42E2-A122-55ACFAE26AF1",
        "label": "Email ",
        "dirty": false
      },
      {
        "id": "8F8C2668-C2C2-4232-92B4-8D5F8939DF32",
        "element": "Checkboxes",
        "text": "Checkboxes",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "checkboxes_E7099700-E3B4-43D2-821A-86D88B15023B",
        "label": "Where did you hear about us? ",
        "options": [
          {
            "value": "friend",
            "text": "Friend",
            "key": "checkboxes_option_E03F23A8-C4C3-4587-AC59-032E83CFE4A2"
          },
          {
            "value": "facebook_instagram",
            "text": "Facebook/Instagram",
            "key": "checkboxes_option_22EA2359-7520-4B00-891E-7347E2BDFFAF"
          },
          {
            "value": "walk_by",
            "text": "Walk By",
            "key": "checkboxes_option_8DBF5992-8B9E-4228-BF0F-D2456FE760C9"
          },
          {
            "value": "other",
            "text": "Other",
            "key": "B66FAF06-B9D1-40B9-9504-FB44CD1F2FE0"
          }
        ],
        "dirty": false
      },
      {
        "id": "F160E3DF-33D4-4AC5-9539-354EB6D96F47",
        "element": "TextArea",
        "text": "Multi-line Input",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "text_area_C4653045-DD5C-48B8-841B-74FEAC7472D5",
        "label": "If other, please specify: ",
        "dirty": false
      },
      {
        "id": "0E5C7558-E0CC-41FC-9256-B771C95A8F4B",
        "element": "LineBreak",
        "text": "Line Break",
        "static": true,
        "required": false,
        "bold": false,
        "italic": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true
      },
      {
        "id": "2EDC0518-DC74-4076-AE2C-579BD21E83C4",
        "element": "Label",
        "text": "Label",
        "static": true,
        "required": false,
        "bold": true,
        "italic": false,
        "content": "<span style=\"font-size: 16px;\">FACE SHAPE</span> ",
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "dirty": false
      },
      {
        "id": "172A5E37-0DB2-47E9-BD80-B1B450864035",
        "element": "TwoColumnRow",
        "text": "Two Column Row",
        "required": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "two_col_row_832F2C5F-3D25-41CC-96CE-D459B72A0527",
        "childItems": [
          "D35FD9B6-73B4-4A9E-80E3-0F51FC890F86",
          "E30B1F3F-D197-44FA-AFAB-382CBCB3EBF3"
        ],
        "isContainer": true
      },
      {
        "id": "CAF1311E-09B1-477D-BDFF-577A7CFA8F66",
        "element": "Checkboxes",
        "text": "Checkboxes",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "checkboxes_62C5003C-C171-44F7-8238-C43361135BAF",
        "label": "How do you like your hair to look? ",
        "options": [
          {
            "value": "neat",
            "text": "neat",
            "key": "checkboxes_option_02796F3D-02CB-4F68-BE95-16AE34F8F62D"
          },
          {
            "value": "messy",
            "text": "messy",
            "key": "checkboxes_option_ABA41300-83A6-4676-A839-94E744CAAD4D"
          },
          {
            "value": "straight",
            "text": "straight",
            "key": "checkboxes_option_D9A04FD1-1B3E-4267-870F-96ED38733A37"
          },
          {
            "value": "curly",
            "text": "curly",
            "key": "B338EB58-D2FC-4C0E-B8E8-B2DEDF302627"
          },
          {
            "value": "strong_shape",
            "text": "strong shape",
            "key": "21B1ABBE-4B67-450B-AA20-5C27EE431B08"
          },
          {
            "value": "soft_shape",
            "text": "soft shape",
            "key": "3085F4C6-C4BE-43E7-8175-1FA9E3C75A8C"
          },
          {
            "value": "textured",
            "text": "textured",
            "key": "910B0026-0DCE-448B-9803-6791ED7FF9BC"
          },
          {
            "value": "shiny",
            "text": "shiny",
            "key": "D56B9946-ADE6-4545-8606-C5F8A26CC268"
          },
          {
            "value": "flat",
            "text": "flat",
            "key": "A026A938-E00A-463D-A964-752A1951514B"
          },
          {
            "value": "full_of_body",
            "text": "full of body",
            "key": "C55B9DF0-2784-45B3-B0C2-469002328F56"
          },
          {
            "value": "wavy",
            "text": "wavy",
            "key": "7E8C924A-D2DF-4FB1-9426-43DBD1B74836"
          },
          {
            "value": "defined",
            "text": "defined",
            "key": "F70878E3-950F-426A-ADC9-43EF78AC5FA9"
          },
          {
            "value": "structured",
            "text": "structured",
            "key": "BB6A512E-9E3C-4877-A595-13B8B27717D3"
          }
        ],
        "dirty": false
      },
      {
        "id": "DC8F6077-98B1-4827-A9F3-39365A06376E",
        "element": "Checkboxes",
        "text": "Checkboxes",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "checkboxes_54164839-0B10-40DA-B4B6-76A36AD598DA",
        "label": "What do you use at home to create your style? ",
        "options": [
          {
            "value": "gel",
            "text": "gel",
            "key": "checkboxes_option_0A55A036-D90D-44AB-8613-D027ED8C10E5"
          },
          {
            "value": "mousse",
            "text": "mousse",
            "key": "checkboxes_option_F01A4CEE-FD93-48BC-89C7-E36188800035"
          },
          {
            "value": "leave_in_conditioner",
            "text": "leave in conditioner",
            "key": "checkboxes_option_E873A800-3F7C-4E62-A925-40A604F9D96C"
          },
          {
            "value": "blow_dry_spray",
            "text": "blow dry spray",
            "key": "3AB4AB65-CF90-409B-B50B-220A1C98F4E8"
          },
          {
            "value": "blow_dry_lotion",
            "text": "blow dry lotion",
            "key": "2CD6426D-5506-4C81-B841-FB7C865187A5"
          },
          {
            "value": "volumiser",
            "text": "volumiser",
            "key": "1E41D216-CA1F-4CD3-B608-35A947FECC84"
          },
          {
            "value": "wax",
            "text": "wax",
            "key": "D77185D9-6A82-4DDA-A029-7D9A172A7D5B"
          },
          {
            "value": "gel_wax",
            "text": "gel wax",
            "key": "A59A8E9D-5CA4-4DD7-85DD-680DF563A2D0"
          },
          {
            "value": "hairspray",
            "text": "hairspray",
            "key": "F49266C8-371F-4C28-B192-FB838502FDEC"
          },
          {
            "value": "shine_products",
            "text": "shine products",
            "key": "9A0798DF-A431-4DDB-B93C-285AFDAE07B8"
          }
        ],
        "dirty": false
      },
      {
        "id": "7C3DBFFA-37DE-49F7-860D-12CB199EF80C",
        "element": "LineBreak",
        "text": "Line Break",
        "static": true,
        "required": false,
        "bold": false,
        "italic": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true
      },
      {
        "id": "A05F9C3E-0E54-4FB3-8A17-E40C21B60B80",
        "element": "Label",
        "text": "Label",
        "static": true,
        "required": false,
        "bold": true,
        "italic": false,
        "content": "<span style=\"font-size: 16px;\">LIFESTYLE</span> ",
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "dirty": false
      },
      {
        "id": "CB56A797-A986-41DE-AF56-7604A81983F0",
        "element": "Checkboxes",
        "text": "Checkboxes",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "checkboxes_42F27EE5-C454-453F-BA17-ADADE484E7D1",
        "label": "How much time do you spend on your hair at the start of the day ",
        "options": [
          {
            "value": "2_minutes",
            "text": "2 minutes",
            "key": "checkboxes_option_8EA5927E-AEE1-4201-B707-6CC51AD56EB9"
          },
          {
            "value": "5_minutes",
            "text": "5 minutes",
            "key": "checkboxes_option_0F304C60-E0C0-4A02-9894-67D1E36EF63D"
          },
          {
            "value": "10_minutes",
            "text": "10 minutes",
            "key": "checkboxes_option_035E829E-3FCD-4591-82D2-8F0C04FB354F"
          },
          {
            "value": "15_minutes",
            "text": "15 minutes",
            "key": "4ED4BCF9-A35B-4AAF-B548-8ADB979495E9"
          },
          {
            "value": "20_minutes_or_longer",
            "text": "20 minutes or longer",
            "key": "2669A5F8-5226-4D2E-8F0C-7562C28386E6"
          }
        ],
        "dirty": false
      },
      {
        "id": "D2CEE432-CB3C-4973-96B3-7AC95456FC6C",
        "element": "RadioButtons",
        "text": "Multiple Choice",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "radiobuttons_C9A69FBE-E9A7-431B-B51D-4140FA9C4833",
        "label": "Do you blow dry your hair? ",
        "options": [
          {
            "value": "yes",
            "text": "Yes",
            "key": "radiobuttons_option_30BDE141-9F96-4937-9297-204F3F209766"
          },
          {
            "value": "no",
            "text": "No",
            "key": "radiobuttons_option_0D03A207-86DD-427E-B37E-05EF185D0E22"
          }
        ],
        "dirty": false
      },
      {
        "id": "4A1026F1-04B3-4C23-B7CF-3B091C7B2B29",
        "element": "RadioButtons",
        "text": "Multiple Choice",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "radiobuttons_8EAC1F18-31E4-4072-B38A-ECD8BD123D2F",
        "label": "Do you leave your hair to dry naturally? ",
        "options": [
          {
            "value": "yes",
            "text": "Yes",
            "key": "radiobuttons_option_2ABD28F4-6049-4F93-92F8-18211C9581E9"
          },
          {
            "value": "no",
            "text": "No",
            "key": "radiobuttons_option_59E816E9-D0E2-41DA-BD1E-448F4558A1AF"
          }
        ],
        "dirty": false
      },
      {
        "id": "6B234E1A-D901-4856-9B79-C56616DAD2B2",
        "element": "TwoColumnRow",
        "text": "Two Column Row",
        "required": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "two_col_row_1B85D2C4-544B-4F9D-8CF2-F4ECDF3A2CF9",
        "childItems": [
          "DDD9738E-9F09-4716-8A18-63525A9CD8CC",
          "F7BE0BF7-73DF-4341-858F-F604EEAB3B19"
        ],
        "isContainer": true
      },
      {
        "id": "E164FF46-F110-42E0-AD23-95097802E421",
        "element": "RadioButtons",
        "text": "Multiple Choice",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "radiobuttons_C0553F41-0576-4E42-B4D1-00026867B04F",
        "label": "Does your hair lack shine? ",
        "options": [
          {
            "value": "yes",
            "text": "Yes",
            "key": "radiobuttons_option_59929D72-541E-476F-B658-D564414B4829"
          },
          {
            "value": "no",
            "text": "No",
            "key": "radiobuttons_option_F0EA8410-0CE9-44B5-8023-624EA5C1F9D9"
          }
        ],
        "dirty": false
      },
      {
        "id": "4745BBB1-E0FD-44C5-9EDD-46175D4A6F48",
        "element": "TwoColumnRow",
        "text": "Two Column Row",
        "required": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "two_col_row_520BEB86-36CE-4740-88D4-6B70375CA390",
        "childItems": [
          "51EAD586-B4D3-4619-A19B-9732EFBF69FD",
          "AC30542A-AC36-4DA0-95CA-F7E6F4D36835"
        ],
        "isContainer": true
      },
      {
        "id": "816AA878-70DB-46D0-8FF9-0FA147400B8F",
        "element": "TwoColumnRow",
        "text": "Two Column Row",
        "required": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "two_col_row_EB8DAFB4-AA97-4370-A935-233D7736D7D7",
        "childItems": [
          "5BB3478D-4136-4F74-BF88-77043E34D577",
          "FB6EA37B-42CC-4B2F-BDBE-C2D364720993"
        ],
        "isContainer": true
      },
      {
        "id": "C8528572-9FFC-4AA7-9D72-8FB5BFEE424C",
        "element": "LineBreak",
        "text": "Line Break",
        "static": true,
        "required": false,
        "bold": false,
        "italic": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true
      },
      {
        "id": "A2110B87-C346-4F18-BCB8-63BA94DAE997",
        "element": "Label",
        "text": "Label",
        "static": true,
        "required": false,
        "bold": true,
        "italic": false,
        "content": "<span style=\"font-size: 16px;\">SKIN TONE</span> ",
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "dirty": false
      },
      {
        "id": "D8F5B370-7A50-43D7-84A6-FBA7A6F0E993",
        "element": "RadioButtons",
        "text": "Multiple Choice",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "radiobuttons_1FF49DD1-89F0-49AA-80D2-F0C9E432D9D7",
        "label": "Do you love the colour of your hair? ",
        "options": [
          {
            "value": "yes",
            "text": "Yes",
            "key": "radiobuttons_option_E72B5B4F-4965-4ACF-9CE4-96F2616034BD"
          },
          {
            "value": "no",
            "text": "No",
            "key": "radiobuttons_option_9B4D14BA-5000-4D2C-9F52-0B757E41A8C0"
          }
        ],
        "dirty": false
      },
      {
        "id": "AFD7ACD7-2986-4DC4-BE48-8128765B8F16",
        "element": "Checkboxes",
        "text": "Checkboxes",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "checkboxes_27882524-1F2E-41DF-90CB-F73244A27328",
        "label": "What would you like your colour result to be? ",
        "options": [
          {
            "value": "natural",
            "text": "Natural",
            "key": "checkboxes_option_E7D0181E-0AFC-4004-8117-CBE79AB7D20A"
          },
          {
            "value": "strong",
            "text": "Strong",
            "key": "checkboxes_option_4B8515A5-7013-4FD8-BC0D-1A915BB1747A"
          },
          {
            "value": "subtle",
            "text": "Subtle",
            "key": "checkboxes_option_4EE92B83-02B9-423D-8F9D-0790DA364EA0"
          },
          {
            "value": "shiny",
            "text": "Shiny",
            "key": "A0E8BB8B-8BB8-483B-8D1E-DC498750E081"
          },
          {
            "value": "matte",
            "text": "Matte",
            "key": "7907D122-C2BD-464E-A0F5-530BBD3B1538"
          },
          {
            "value": "stripy",
            "text": "Stripy",
            "key": "13434DDC-7352-478A-911C-90DF78F75D8A"
          },
          {
            "value": "bright",
            "text": "Bright",
            "key": "65C46E5E-385E-4775-8375-FBA99760166C"
          },
          {
            "value": "cover_grey",
            "text": "Cover grey",
            "key": "A39E833C-429B-4D5E-A290-CF2D19D977A1"
          },
          {
            "value": "natural",
            "text": "Natural",
            "key": "5C62C259-BF14-45E2-B99B-07527DE0EB90"
          },
          {
            "value": "blended",
            "text": "Blended",
            "key": "52DF2E95-23BB-4C0A-8A3B-9009557B7082"
          },
          {
            "value": "obviously_coloured",
            "text": "Obviously coloured",
            "key": "9D56D374-17AA-46B0-844C-3D9E6157E312"
          },
          {
            "value": "lots_of_contrast",
            "text": "Lots of contrast",
            "key": "AE96F916-3851-4B7B-A882-B2939C8257F1"
          }
        ],
        "dirty": false
      },
      {
        "id": "BC8606FC-EBF0-4629-8987-9F781D54180F",
        "element": "DatePicker",
        "text": "Date",
        "required": false,
        "readOnly": false,
        "defaultToday": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "hh:mm aa",
        "showTimeSelect": false,
        "showTimeSelectOnly": false,
        "showTimeInput": false,
        "field_name": "date_picker_EE5D210B-292D-4681-9621-BE5D83E0D20B",
        "label": "Date submitted ",
        "dirty": false
      },
      {
        "id": "FB6EA37B-42CC-4B2F-BDBE-C2D364720993",
        "element": "Checkboxes",
        "text": "Checkboxes",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "checkboxes_D3A9EA10-6B79-47C0-9B97-1F4BFBAFF1B5",
        "label": "If yes, do you wear it: ",
        "options": [
          {
            "value": "curly",
            "text": "Curly",
            "key": "checkboxes_option_F54FB9E5-6607-4D85-AEA9-D12F20EDE1C0"
          },
          {
            "value": "straight",
            "text": "Straight",
            "key": "checkboxes_option_F9ACDD54-CEFA-4BA6-864E-282BA7E52EF5"
          },
          {
            "value": "hair_up",
            "text": "Hair up",
            "key": "checkboxes_option_8882AB3B-1EAC-4D30-A364-507D83000485"
          },
          {
            "value": "messy",
            "text": "Messy",
            "key": "525A56FE-9790-47D6-A6EC-724EB1992EBE"
          },
          {
            "value": "big",
            "text": "Big",
            "key": "9E9B31D0-7B44-4D50-A4BD-6F3FBA97AEC9"
          },
          {
            "value": "flat",
            "text": "Flat",
            "key": "8F464731-7DA4-49E1-991A-DB593AB08821"
          }
        ],
        "col": 1,
        "parentId": "816AA878-70DB-46D0-8FF9-0FA147400B8F",
        "parentIndex": 20,
        "dirty": false
      },
      {
        "id": "5BB3478D-4136-4F74-BF88-77043E34D577",
        "element": "RadioButtons",
        "text": "Multiple Choice",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "radiobuttons_352FA059-5A6D-4EE8-ABFD-C5ED462742E3",
        "label": "Do you change your hair for a special occasion? ",
        "options": [
          {
            "value": "yes",
            "text": "Yes",
            "key": "radiobuttons_option_8A4A30F9-5530-4A23-8F96-4D2CA48627DB"
          },
          {
            "value": "no",
            "text": "No",
            "key": "radiobuttons_option_780F4FF1-BAF9-42C0-9438-591AA14006D8"
          }
        ],
        "dirty": false,
        "col": 0,
        "parentId": "816AA878-70DB-46D0-8FF9-0FA147400B8F",
        "parentIndex": 21
      },
      {
        "id": "AC30542A-AC36-4DA0-95CA-F7E6F4D36835",
        "element": "Checkboxes",
        "text": "Checkboxes",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "checkboxes_2E9DF628-7B84-4FC3-80ED-BF4ECDF2323D",
        "label": "If yes, in the sea/chlorine? ",
        "options": [
          {
            "value": "sea",
            "text": "Sea",
            "key": "checkboxes_option_BD6FB127-71FA-4FB9-9A6D-B3A86DDFCEC4"
          },
          {
            "value": "chlorine",
            "text": "Chlorine",
            "key": "checkboxes_option_B9424A5D-BCBE-43DC-8865-C64249C0E226"
          }
        ],
        "col": 1,
        "parentId": "4745BBB1-E0FD-44C5-9EDD-46175D4A6F48",
        "parentIndex": 19,
        "dirty": false
      },
      {
        "id": "51EAD586-B4D3-4619-A19B-9732EFBF69FD",
        "element": "RadioButtons",
        "text": "Multiple Choice",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "radiobuttons_A7DB01EB-8C0A-46FF-955A-E7EF3891E725",
        "label": "Do you swim? ",
        "options": [
          {
            "value": "yes",
            "text": "Yes",
            "key": "radiobuttons_option_49B69F7A-44E7-433B-A9DD-3FF693312B8A"
          },
          {
            "value": "no",
            "text": "No",
            "key": "radiobuttons_option_9EBE8D48-CEFA-4B0A-9BFE-5CA0CF61F4C8"
          }
        ],
        "dirty": false,
        "col": 0,
        "parentId": "4745BBB1-E0FD-44C5-9EDD-46175D4A6F48",
        "parentIndex": 19
      },
      {
        "id": "F7BE0BF7-73DF-4341-858F-F604EEAB3B19",
        "element": "RadioButtons",
        "text": "Multiple Choice",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "radiobuttons_02274C3C-6AE2-465B-8B05-2D5EBC67A72D",
        "label": "If yes, do you use a thermal protector? ",
        "options": [
          {
            "value": "yes",
            "text": "Yes",
            "key": "radiobuttons_option_31130325-BE23-49CD-8995-EB11EC944992"
          },
          {
            "value": "no",
            "text": "No",
            "key": "radiobuttons_option_8F2B9E44-EBD9-4472-8D0A-9C354D658607"
          }
        ],
        "col": 1,
        "parentId": "6B234E1A-D901-4856-9B79-C56616DAD2B2",
        "parentIndex": 17,
        "dirty": false
      },
      {
        "id": "DDD9738E-9F09-4716-8A18-63525A9CD8CC",
        "element": "RadioButtons",
        "text": "Multiple Choice",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "radiobuttons_FCA75EB3-3FBC-405D-8853-08372F369E3B",
        "label": "Do you straighten your hair? ",
        "options": [
          {
            "value": "yes",
            "text": "Yes",
            "key": "radiobuttons_option_19A50956-26D8-469A-8E6B-7A7A701C37E3"
          },
          {
            "value": "no",
            "text": "No",
            "key": "radiobuttons_option_53DECF92-E439-4AE8-A66C-76DD18C04F26"
          }
        ],
        "col": 0,
        "parentId": "6B234E1A-D901-4856-9B79-C56616DAD2B2",
        "parentIndex": 17,
        "dirty": false
      },
      {
        "id": "E30B1F3F-D197-44FA-AFAB-382CBCB3EBF3",
        "element": "Checkboxes",
        "text": "Checkboxes",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "checkboxes_BAE3A44C-9449-4698-8247-A89CCEB10E41",
        "label": "If no - then what is it about your style that you find difficult? ",
        "options": [
          {
            "value": "length",
            "text": "length",
            "key": "checkboxes_option_5FC8C039-9572-480F-A395-7507B1E2492B"
          },
          {
            "value": "texture_thick_fine_",
            "text": "texture (thick/fine/...)",
            "key": "checkboxes_option_9CE5FCC5-DD97-42FB-ABF2-DDDF82D2A6F6"
          },
          {
            "value": "height",
            "text": "height",
            "key": "checkboxes_option_25527B38-63DD-4CF4-BF00-6123BFC5A8AA"
          },
          {
            "value": "style_management",
            "text": "style management",
            "key": "58FEDB47-010B-4E21-85EB-8C88AFA7F497"
          },
          {
            "value": "frizz_growth_patterns_crowns_cowlicks_",
            "text": "frizz growth patterns (crowns, cowlicks)",
            "key": "EE4BAA7E-3731-46D2-8D72-D3B31255C46C"
          }
        ],
        "dirty": false,
        "col": 1,
        "parentId": "172A5E37-0DB2-47E9-BD80-B1B450864035",
        "parentIndex": 9
      },
      {
        "id": "D35FD9B6-73B4-4A9E-80E3-0F51FC890F86",
        "element": "RadioButtons",
        "text": "Multiple Choice",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "radiobuttons_DF5234DB-B9C0-4827-A107-4C7946AE0AB1",
        "label": "Are you entirely happy with your current cut? ",
        "options": [
          {
            "value": "yes",
            "text": "Yes",
            "key": "radiobuttons_option_F03BA6B7-8DAC-497F-8DE8-2A0A2B6B1A4A"
          },
          {
            "value": "no",
            "text": "No",
            "key": "radiobuttons_option_F90EB42F-7754-4968-AA6B-F91F855D2689"
          }
        ],
        "dirty": false,
        "col": 0,
        "parentId": "172A5E37-0DB2-47E9-BD80-B1B450864035",
        "parentIndex": 9
      },
      {
        "id": "78A20A5C-9129-4B2E-A173-3ACD771C28E9",
        "element": "Header",
        "text": "Header Text",
        "static": true,
        "required": false,
        "bold": true,
        "italic": true,
        "content": "<p style=\"text-align:center;\"><span style=\"font-size: 36px;\">New Client Consultation Form</span> ",
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "col": 1,
        "parentId": "1E1B9390-93F6-4053-B2C5-736417F2662B",
        "parentIndex": 0,
        "dirty": false
      },
      {
        "id": "9AE128BE-A482-4074-A6B1-9C40CA8FECB1",
        "element": "Logo",
        "text": "Logo",
        "required": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "src": "https://res.cloudinary.com/clientdiary/image/upload/v1649810616/SalonLogo_fitgj3.png",
        "height": 150,
        "field_name": "logo_AD608D50-0CC9-4261-A031-84327BE3E6F6",
        "col": 0,
        "parentId": "1E1B9390-93F6-4053-B2C5-736417F2662B",
        "parentIndex": 0,
        "dirty": false,
        "center": true
      },
      {
        "id": "2C516DCF-3AC7-4C36-8405-A43BBA4F0F41",
        "element": "RadioButtons",
        "text": "Multiple Choice",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "inline": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "radiobuttons_D547B5F0-9087-44BB-BF93-A9D4540129A4",
        "label": "Titles ",
        "options": [
          {
            "value": "mr",
            "text": "Mr",
            "key": "radiobuttons_option_111FC239-BF83-4B3B-86D4-27A13B8A67FA"
          },
          {
            "value": "mrs",
            "text": "Mrs",
            "key": "radiobuttons_option_A9A0E856-4913-4209-B65A-AE89A113C889"
          },
          {
            "value": "ms",
            "text": "Ms",
            "key": "radiobuttons_option_9DDEF8CD-AB8D-48B6-8DC1-BD594AD3A2AA"
          }
        ],
        "col": 0,
        "parentId": "27159AD6-96EC-4027-ADC6-A6071D36C0E2",
        "parentIndex": 1,
        "dirty": false
      },
      {
        "id": "D267A8B6-0B9C-4D7D-9922-929B36CA87CE",
        "element": "TextInput",
        "text": "Text Input",
        "required": true,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "text_input_620F5D88-EA32-4B7F-A7F2-3F6A18229BF8",
        "label": "Name ",
        "col": 1,
        "parentId": "27159AD6-96EC-4027-ADC6-A6071D36C0E2",
        "parentIndex": 1,
        "dirty": false
      },
      {
        "id": "A0309315-5B08-496C-849C-34EF4A11FEF3",
        "element": "TextInput",
        "text": "Text Input",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "text_input_B817CB7E-535E-40E1-BE3D-ABF5FC52E1A3",
        "label": "Home Phone ",
        "col": 0,
        "parentId": "CB7D2471-B014-4CF0-A234-C1D830E0B940",
        "parentIndex": 7,
        "dirty": false
      },
      {
        "id": "D5D251DB-5620-411B-8A59-7A1051B7AB48",
        "element": "TextInput",
        "text": "Text Input",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "text_input_D6BE23C3-5444-48E4-B847-D786C0FE6041",
        "label": "Mobile No. ",
        "col": 1,
        "parentId": "CB7D2471-B014-4CF0-A234-C1D830E0B940",
        "parentIndex": 3,
        "dirty": false
      },
      {
        "id": "D515565B-72EB-4689-B278-82D4295BA160",
        "element": "TextInput",
        "text": "Text Input",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "text_input_4B462B58-9693-45C5-8225-D3C58CAA943B",
        "label": "Work No. ",
        "col": 2,
        "parentId": "CB7D2471-B014-4CF0-A234-C1D830E0B940",
        "parentIndex": 3,
        "dirty": false
      },
      {
        "id": "CB0DE3C1-2158-4C87-8657-2116E25D2A8E",
        "element": "DatePicker",
        "text": "Date",
        "required": false,
        "readOnly": false,
        "defaultToday": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "hh:mm aa",
        "showTimeSelect": false,
        "showTimeSelectOnly": false,
        "showTimeInput": false,
        "field_name": "date_picker_031AC190-46FD-45AE-A74F-613E2ABCCB0A",
        "label": "Date of Birth ",
        "col": 2,
        "parentId": "27159AD6-96EC-4027-ADC6-A6071D36C0E2",
        "parentIndex": 1,
        "dirty": false
      },
      {
        "id": "48F78D4A-B4C2-4B29-B6E5-58DF5F559EAF",
        "element": "Signature",
        "text": "Signature",
        "required": false,
        "readOnly": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "signature_AE1EFC1A-45C7-41DD-8C62-33202AC0035E",
        "label": "Signature"
      }
    ]
  },
  {
    id: '3',
    name: 'Client Feedback Form',
    formData: [
      {
        "id": "E3854BB1-80BA-49B6-8777-E6D660779716",
        "element": "TwoColumnRow",
        "text": "Two Column Row",
        "required": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "two_col_row_EA419C70-0B81-4EAC-92A9-3523C921FA9A",
        "childItems": [
          "129F1FD8-219A-41CD-A292-9779640F3037",
          "8C22D1CF-D399-4B6D-91BF-ECB4141735A6"
        ],
        "isContainer": true
      },
      {
        "id": "DBF503C1-F126-46B7-AF94-20103BEFFE2E",
        "element": "Paragraph",
        "text": "Paragraph",
        "static": true,
        "required": false,
        "bold": false,
        "italic": false,
        "content": "We're always looking for ways to improve what we do and we'd love to get your feedback. From the list below, please give a rating for how important each item on the list is to you.  For example, if the quality of refreshments is important to you, you may give that an 8 and if Advice on your service was not improtant to you, you might rate it a 3. ",
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "dirty": false
      },
      {
        "id": "5490B419-7A34-45AC-A32C-72DCA299B116",
        "element": "LineBreak",
        "text": "Line Break",
        "static": true,
        "required": false,
        "bold": false,
        "italic": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true
      },
      {
        "id": "95353EC4-5415-4D04-9602-D7B1F0984E4A",
        "element": "Header",
        "text": "Header Text",
        "static": true,
        "required": false,
        "bold": false,
        "italic": false,
        "content": "How important are the following to you? ",
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "dirty": false
      },
      {
        "id": "02B8291E-E30B-46F1-AF9E-F7D1610A7764",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_FA7BCEA8-5438-4D6D-BAE4-B3D4B3693AAE",
        "label": "Service Levels ",
        "dirty": false
      },
      {
        "id": "4BF8A771-9A96-4D5B-B9DD-EF42BCECFCE9",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_6ABF9CB0-E6AD-4E07-856F-0C2949B957AA",
        "label": "Quality of work ",
        "dirty": false
      },
      {
        "id": "386CEAC3-DF49-416A-B5D2-7C2C59DE2F77",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_ABAAEC8D-6EC0-455F-9C56-6E42CE01188D",
        "label": "Extras (refreshments, nice touches, etc) ",
        "dirty": false
      },
      {
        "id": "AF21EADE-23CD-4042-91B4-3B2D93E4B1C2",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_C54E1D59-6640-4D47-A4E2-528616A91E32",
        "label": "Price ",
        "dirty": false
      },
      {
        "id": "EE0DAE0E-1C29-49D6-8B8B-0D19624D791A",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_264904EE-EAB8-4B30-9963-D39DDCE7867D",
        "label": "Running on time ",
        "dirty": false
      },
      {
        "id": "59616F2C-265B-4566-951A-811F5911E9AA",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_52245910-E976-4DE9-8C54-0071C03DB5B0",
        "label": "Length of appointment  ",
        "dirty": false
      },
      {
        "id": "A026938D-BC6C-468A-A652-AFD41FBD6EAB",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_A355FA64-4158-42E8-8626-A566BB5F3181",
        "label": "Home usage products ",
        "dirty": false
      },
      {
        "id": "FE711E60-EBD7-4FAC-9181-8EA9BD241046",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_BBB8257C-935F-4557-BA8B-6CE9B481B0CA",
        "label": "Advice ",
        "dirty": false
      },
      {
        "id": "45F0EC1E-A29B-43DA-87C1-F102739532F5",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_DECA10A6-77AF-4BEE-9DAD-AAEC6C9299FE",
        "label": "Music Style / Volume ",
        "dirty": false
      },
      {
        "id": "126983BF-E202-4F9D-A45F-B7B39D11B82F",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_355E4622-3D07-42DA-9264-F18346E03BEE",
        "label": "Atmosphere ",
        "dirty": false
      },
      {
        "id": "821E268F-9E6B-4279-BD58-820B179D3215",
        "element": "Range",
        "text": "Range",
        "required": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "step": 1,
        "default_value": "5",
        "min_value": 1,
        "max_value": "10",
        "min_label": "Not important",
        "max_label": "Very important",
        "field_name": "range_FE1617B2-907C-4F26-AE21-6FCCFEB4E814",
        "label": "Follow up ",
        "dirty": false
      },
      {
        "id": "46A90D11-43B7-4D7C-BCF4-87336CE7AB33",
        "element": "LineBreak",
        "text": "Line Break",
        "static": true,
        "required": false,
        "bold": false,
        "italic": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true
      },
      {
        "id": "13BD7B59-81C0-42A2-98A3-ED7C2D84FA62",
        "element": "TextArea",
        "text": "Multi-line Input",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "text_area_BAB2196E-5E6D-4E19-85FE-346C393210FB",
        "label": "Thank you so much for taking the time to help us to make your future experiences better.  If you have any suggestions as to how we could improve or just what you love (or don't love) about visiting, we'd love to hear what you think below: ",
        "dirty": false
      },
      {
        "id": "0CABE99B-D9F9-475D-B7BA-9C6EAAD041CD",
        "element": "Checkboxes",
        "text": "Checkboxes",
        "required": false,
        "canHaveAnswer": true,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "field_name": "checkboxes_60F047CE-DE96-4622-BB41-5BBD2489E209",
        "label": "We occasionally choose comments to use in marketing.  Please tick this box if you give us permission to use any comments or feedback you've provided. We will not use them if you do not tick this box.  Thank you. ",
        "options": [
          {
            "value": "i_provide_permission_to_use_my_comments_in_marketing",
            "text": "I provide permission to use my comments in marketing",
            "key": "checkboxes_option_594BEBA7-F99D-4551-9DA7-D6FFDB22D62A"
          }
        ],
        "dirty": false
      },
      {
        "id": "8C22D1CF-D399-4B6D-91BF-ECB4141735A6",
        "element": "Logo",
        "text": "Logo",
        "required": false,
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "src": "https://res.cloudinary.com/clientdiary/image/upload/v1649810616/SalonLogo_fitgj3.png",
        "height": 150,
        "field_name": "logo_DBE84DED-4A82-429D-9DC3-D2074FFFC6E3",
        "col": 1,
        "parentId": "E3854BB1-80BA-49B6-8777-E6D660779716",
        "parentIndex": 0
      },
      {
        "id": "129F1FD8-219A-41CD-A292-9779640F3037",
        "element": "Header",
        "text": "Header Text",
        "static": true,
        "required": false,
        "bold": false,
        "italic": false,
        "content": "Thank you for spending time with us ",
        "canHavePageBreakBefore": true,
        "canHaveAlternateForm": true,
        "canHaveDisplayHorizontal": true,
        "canHaveOptionCorrect": true,
        "canHaveOptionValue": true,
        "canPopulateFromApi": true,
        "col": 0,
        "parentId": "E3854BB1-80BA-49B6-8777-E6D660779716",
        "parentIndex": 0,
        "dirty": false
      }
    ]
  }
]