import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { ReactFormBuilder, ElementStore, ReactFormGenerator } from '@clientdiary/react-form-builder2';
import '@clientdiary/react-form-builder2/dist/app.css';

import {
  Box, Stack, Container,
  Card, CardContent, Typography, AlertTitle,
  Button, CircularProgress, FormControlLabel, Checkbox, IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField,
  Alert as MuiAlert, Snackbar
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

import PreviewForm from './PreviewForm';
import { templateForms } from './FormTemplate';

const axios = require('axios');

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const items = [
  {
    key: 'Header',
  }, {
    key: 'Label',
  }, {
    key: 'Paragraph',
  }, {
    key: 'LineBreak',
  }, {
    key: 'TextInput',
  }, {
    key: 'NumberInput',
  }, {
    key: 'TextArea',
  }, {
    key: 'RadioButtons',
  }, {
    key: 'Checkboxes',
  }, {
    key: 'TwoColumnRow',
  }, {
    key: 'ThreeColumnRow',
  }, {
    key: 'FourColumnRow',
  }, {
    key: 'Image',
  }, {
    key: 'Rating',
  }, {
    key: 'DatePicker',
    canDefaultToday: true,
    canReadOnly: true,
    dateFormat: 'dd/MM/yyyy',
    timeFormat: 'hh:mm aa',
    showTimeSelect: false,
    showTimeSelectOnly: false,
    showTimeInput: false,
    name: 'Date',
    icon: 'far fa-calendar-alt',
    label: 'Placeholder label',
    field_name: 'date_picker_'
  }, {
    key: 'Signature',
  }, {
    key: 'Range',
  }, {
    key: 'Camera',
  }
];

// const appUrl = 'http://localhost:3000/methods/';
const appUrl = 'https://app.clientdiary.com/methods/';

function App() {
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const [loading, setLoading] = useState(true);
  const [linkValid, setLinkValid] = useState(true);

  const [toolbarItems, setToolbarItems] = useState(items);

  const [businessName, setBusinessName] = useState('Forms');
  const [logoUrl, setLogoUrl] = useState('');
  const [formName, setFormName] = useState('');
  const [formAutoLock, setFormAutoLock] = useState(true);

  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const [pinNumber, setPinNumber] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarStatus, setSnackbarStatus] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('Form saved!');

  const [formDataToComplete, setFormDataToComplete] = useState([]);
  const [answerData, setAnswerData] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isFormLocked, setIsFormLocked] = useState(false);

  useEffect(() => {
    if (urlParams.has('fid') || urlParams.has('fcid')) {
      axios.post(`${appUrl}getBusinessDetailsForms`, [urlParams.get('fid'), urlParams.get('fcid')])
      .then(function (response) {
        const result = response.data;
        
        if (result.available === false || (!result.testAccount && (result.subscription === undefined || (result.subscription.status !== 'active' && result.subscription.status !== 'trialing' && result.subscription.status !== 'past_due')))) {
          setLinkValid(false);
          setLoading(false);
        } else {
          items.unshift({
            key: 'Logo',
            name: 'Logo',
            label: '',
            icon: 'far fa-image',
            field_name: 'logo_',
            src: result.logoUrl
          })

          setToolbarItems(items);
          setBusinessName(result.businessName);
          setLogoUrl(result.logoUrl);
          setFormDataToComplete(result.formDataToComplete);
          setAnswerData(result.answerData);
          setIsFormLocked(result.isFormLocked);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    } else {
      setLinkValid(false);
      setLoading(false);
    }
  }, [urlParams]);

  const onLoad = () => {
    return axios.post(`${appUrl}getForm`, [urlParams.get('fid')])
    .then(response => {
      setFormName(response.data.formName);
      setFormAutoLock(response.data.formAutoLock);
      return response.data.formData
    });
  }

  const onPost = (data) => {
    return;
  }

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault()
    }

    if (urlParams.get('mode') === 'edit') {
      // Employee editing client's form
      axios.post(`${appUrl}submitFormClient`, [urlParams.get('fcid'), answerData, pinNumber])
      .then(response => {
        if (response.data === 'Authorization failed') {
          setSnackbarStatus('error');
          setSnackbarMessage('Authorization failed. PIN is wrong or no permission to edit form.');
          setOpenSnackbar(true);
        } else {
          setOpenVerifyDialog(false);
          setPinNumber('');
          setSnackbarStatus('success');
          setSnackbarMessage('Form saved!');
          setOpenSnackbar(true);
        }
        return response
      })
    } else {
      // Employee updating the form builder
      const formData = ElementStore.state.data;
      axios.post(`${appUrl}updateForm`, [urlParams.get('fid'), formData, formName, formAutoLock, pinNumber])
      .then(response => {
        if (response.data === 'Authorization failed') {
          setSnackbarStatus('error');
          setSnackbarMessage('Authorization failed. PIN is wrong or no permission to edit form.');
          setOpenSnackbar(true);
        } else {
          setOpenVerifyDialog(false);
          setPinNumber('');
          setSnackbarStatus('success');
          setSnackbarMessage('Form saved!');
          setOpenSnackbar(true);
        }
        return response
      })
    }
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleSubmitFormClient = (e) => {
    e = e.filter(x => x.name !== undefined);

    if (urlParams.get('mode') === 'edit') {
      console.log('edit');
      setAnswerData(e);
      setOpenVerifyDialog(true);
    } else {
      axios.post(`${appUrl}submitFormClient`, [urlParams.get('fcid'), e])
      .then(response => {
        if (response.data === 'Done') {
          setSubmitted(true);
        }
        return response
      })
    }
  }


  const renderContentV2 = () => {
    if (!linkValid) {
      return (
        <Card variant="outlined" style={{ margin: 10 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>Form link is incorrect.</Typography>
            <br />
            {urlParams.has('fid') ?
              <Typography variant="body2" gutterBottom>Please follow the edit button in Forms Settings in Client Diary.</Typography>
              :
              <Typography variant="body2" gutterBottom>Please contact the business to request another form.</Typography>
            }
            <br />
            <Typography variant="body2">Thank you,</Typography>
            <Typography variant="body2"><strong><i>Client Diary Forms System</i></strong></Typography>
          </CardContent>
        </Card>
      )
    } else {
      if (urlParams.has('fid')) {
        // Business editing form
        return (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Form Editing - {businessName}</Typography>
            <Stack spacing={2}>
              <TextField
                autoFocus
                variant="filled"
                margin="normal"
                label="Form Name"
                fullWidth
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
              />
              
              <MuiAlert severity="info">
                <AlertTitle>Auto-Lock</AlertTitle>
                <Typography variant="subtitle2">If Auto-Lock is ON</Typography>
                After client submits the form, it will be locked and not editable/updatable.
                <br/>
                To allow client to edit/update their answers, you need to manually unlock it in client's forms page.
                <br />
                <br />
                <Typography variant="subtitle2">If Auto-Lock is OFF</Typography>
                Form remains editable/updatable and clients can resubmit their answers. This will overwrites old answers.
                <br/>
                To lock-in the answers and stop client from submitting new answers, you need to manually lock it in client's forms page.
                <br />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formAutoLock}
                      onChange={(e) => setFormAutoLock(e.target.checked)}
                    />
                  }
                  label="Auto-Lock after clients submit their form"
                />
              </MuiAlert>

              <Stack spacing={2} direction="row">
                <Button variant="contained" color="primary" onClick={() => setOpenVerifyDialog(true)}>Save Form</Button>
                <PreviewForm toolbarItems={toolbarItems} />
              </Stack>
  
              <ReactFormBuilder
                onPost={onPost}
                onLoad={onLoad}
                toolbarItems={toolbarItems}
              />
            </Stack>
          </Box>
        )
      } else if (urlParams.has('fcid')) {
        // Client submitting form
        return (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h6">
              {businessName}
              <IconButton component="span" sx={{ ml: 1 }} onClick={() => window.print()} className="no-print">
                <PrintIcon />
              </IconButton>
            </Typography>
            <Typography variant="h4" gutterBottom>{formName}</Typography>
            {isFormLocked && !urlParams.has('mode') ?
              <Card variant="outlined" style={{ margin: 10 }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>This form is locked.</Typography>
                  <br />
                  <Typography variant="body2">If you are a client and want to edit/update your answers, please contact us to unlock your form.</Typography>
                  <br />
                  <Typography variant="body2">Thank you,</Typography>
                  <Typography variant="body2"><strong><i>{businessName}</i></strong></Typography>
                </CardContent>
              </Card>
              :
              <>
                {submitted ?
                  <Card variant="outlined" style={{ margin: 10 }}>
                    <CardContent>
                      <Typography variant="h5" gutterBottom>Your form has been submitted.</Typography>
                      <br />
                      <Typography variant="body2">Thank you,</Typography>
                      <Typography variant="body2"><strong><i>{businessName}</i></strong></Typography>
                      <br />
                      <Typography variant="subtitle2"><i>You can now safely close this page.</i></Typography>
                    </CardContent>
                  </Card>
                  :
                  <ReactFormGenerator
                    toolbarItems={toolbarItems}
                    answer_data={answerData}
                    action_name="Save"
                    form_action="/"
                    form_method="POST"
                    data={formDataToComplete}
                    submitButton={<Button type="submit" color="primary" variant="contained" className="no-print">Submit</Button>}
                    onSubmit={handleSubmitFormClient}
                    backButton={<></>}
                    hide_actions={urlParams.get('mode') === 'view'}
                    read_only={urlParams.get('mode') === 'view'}
                  />
                }
              </>
            }
          </Box>
        )
      }
    }
  }

  const renderPreviewTemplate = () => {
    const previewData = templateForms.find(f => f.id === urlParams.get('preview'));

    if (previewData === undefined) {
      return (
        <Card variant="outlined" style={{ margin: 10 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>Template link is incorrect.</Typography>
            <br />
            <Typography variant="body2" gutterBottom>Please follow the preview template button in in Client Diary.</Typography>
            <br />
            <Typography variant="body2">Thank you,</Typography>
            <Typography variant="body2"><strong><i>Client Diary Forms System</i></strong></Typography>
          </CardContent>
        </Card>
      )
    } else {
      return (
        <Box sx={{ mt: 2, mb: 2 }}>
          <ReactFormGenerator
            // toolbarItems={toolbarItems}
            answer_data={[]}
            action_name="Save"
            form_action="/"
            form_method="POST"
            data={previewData.formData}
            submitButton={<Button type="submit" color="primary" variant="contained" className="no-print" disabled>Submit</Button>}
            onSubmit={handleSubmitFormClient}
            backButton={<></>}
            hide_actions={urlParams.get('mode') === 'view'}
            read_only
          />
        </Box>
      )
    }
  }

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>{businessName === '' ? 'Client Diary' : businessName}</title>
      </Helmet>

      { loading ?
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CircularProgress />
        </div>
        :
        <>
          { urlParams.has('preview') ?
            renderPreviewTemplate()
            :
            renderContentV2()
          }
        </>
      }

      <Dialog
        open={openVerifyDialog}
        onClose={() => setOpenVerifyDialog(false)}
      >
        <DialogTitle>Authentication</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your login PIN to authorize form editing
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              margin="normal"
              size="small"
              label="PIN Number"
              fullWidth
              value={pinNumber}
              type="password"
              onChange={(e) => setPinNumber(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setOpenVerifyDialog(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>Authorize</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarStatus} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default App;
